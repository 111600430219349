.logo {
  object-fit: contain;
  width: 165px;
  height: auto;
  font-family: 'object-fit: contain;';
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
