.ReactModal__Content {
    -webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-70deg);
	-moz-transform: rotateX(-70deg);
	-ms-transform: rotateX(-70deg);
	transform: rotateX(-70deg);
    -webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms;
	opacity: 0.1;
}

.ReactModal__Content--after-open{
    -webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
	opacity: 1;
}

.ReactModal__Content--before-close{
    -webkit-transform: rotateX(-70deg);
	-moz-transform: rotateX(-70deg);
	-ms-transform: rotateX(-70deg);
    transform: rotateX(-70deg);
    -webkit-transition: all 200ms;
	-moz-transition: all 200ms;
	transition: all 200ms;
	opacity: 0.1;
}

.ReactModal__Overlay {
    opacity: 0.1;
	z-index: 99;
    transition: opacity 300ms ease-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0.1;
    transition: opacity 200ms ease-out;
}
