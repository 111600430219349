$brandprimary: #e30c00;
$brandsecondary: rgb(255, 255, 255);
$branddark: rgb(32, 32, 32);
$accent1: #fca311;

$navbarcolor: #e30b00da;
$navbarLinkColor: #fcfcfc;
$navbarLinkHoverColor: $branddark;

$footerBackgroundColor: $branddark;
$darkText: $branddark;

$successcolor: rgb(105, 236, 73);
$failcolor: rgb(175, 38, 38);
$warningcolor: #f47c00;
$mutedcolor: #8d827a;
