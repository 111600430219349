/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dots {
  max-height: 75px;
  width: auto;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .dots-mobile {
    max-height: 50px;
  }
}
